import React from "react";
import { IoLocationOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

const Footer = () => {
  const name = "Gyan Bhawan Publication";
  const emailId = "gyanbhawanpd@gmail.com";
  const contactNumber = "7004890292, 7992416667";
  const address = "Khazanchi Road, Patna, BR";

  return (
    <footer className="bg-[#111723] text-[#f2f3f8]">
      <div className="container mx-auto px-4 py-8 flex flex-col items-center">
        <p className="text-2xl font-semibold mb-2">{name}</p>
        <p className="flex items-center text-lg mb-2">
          <IoLocationOutline className="mr-2" /> {address}
        </p>
        <p className="flex items-center text-lg mb-2">
          <FaWhatsapp className="mr-2" /> {contactNumber}
        </p>
        <p className="flex items-center text-lg">
          <MdOutlineEmail className="mr-2" /> {emailId}
        </p>
      </div>
      <div className="bg-[#0d111b] text-center py-4">
        <p className="text-sm">
          &copy; 2024 Gyan Bhawan Publication. All rights reserved | Developed by: R S Tech
        </p>
      </div>
    </footer>
  );
};

export default Footer;
