import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import About from './components/About';
import Contact from './components/Contact';
import NewProducts from './components/NewProducts';
import FeatureProducts from './components/FeatureProducts';
import BestSelling from './components/BestSelling';

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Layout/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/colouringBooks' element={<NewProducts/>}/>
    <Route path='/writingBooks' element={<FeatureProducts/>}/>
    <Route path='/bestselling' element={<BestSelling/>}/>
    </Routes>
    </BrowserRouter>
    
    </>
      
   
   
  )
}

export default App;
