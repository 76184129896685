import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="bg-[#d8794a] py-16">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl text-white mb-4">Gyan Bhawan Publication</h1>
          <h4 className="text-2xl text-white mb-6">Contact Us for Children Books</h4>
          <div className="flex flex-col items-center space-y-8">
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
              <p className="text-lg">
                Gyan Bhawan Publication<br />
                Khazanchi Road Opp Rishi Sweets<br />
                Patna-800004<br />
                Phone: 7004890292 (For Children Books)<br />
                Email: gyanbhawanpd@gmail.com
              </p>
            </div>
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
              <p className="text-lg">
                Branch 1:<br />
                Gyan Bhawan Publication<br />
                Raj Kumar complex Khazanchi Road<br />
                Patna-800004<br />
                Phone: 7992416667 (For Children Books)<br />
                Email: gyanbhawanpd@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
