import React from 'react'
import Navbar from './Navbar'
import Card from './Card'
import Footer from './Footer'
import cursive1big from "../image/cursive1big.png";
import cursive2big from "../image/cursive2big.png";
import cursive3big from "../image/cursive3big.png";
import cursive4big from "../image/cursive4big.png";
import cursive5big from "../image/cursive5big.png";
const FeatureProducts = () => {
  const cursiveBookSeries ={
    heading:"Cursive Book Serires",
    cards:[
      {image: cursive1big, name: "Vidya Cursive 1 Jumbo"},
      {image: cursive2big, name: "Vidya Cursive 2 Jumbo"},
      {image: cursive3big, name: "Vidya Cursive 3 Jumbo"},
      {image: cursive4big, name: "Vidya Cursive 4 Jumbo"},
      {image: cursive5big, name: "Vidya Cursive 5 Jumbo"}
    ]
  };
  return (
    <>
    <Navbar/>
    <Card data={cursiveBookSeries}/>
    <Footer/>
    </>
  )
}

export default FeatureProducts