import React from 'react'
import Card from './Card'
import Footer from './Footer'
import Navbar from './Navbar'
import alphabetColour from "../image/alphabetColour.jpg"
import animalColour from "../image/animalColour.jpg"
import birdsColour from "../image/birdsColour.jpg"
import cartoonColour from "../image/cartoonColour.jpg"
import flowerColour from "../image/flowerColour.jpg"
import fruitsColour from "../image/fruitsColour.jpg"
import krishnaColour from "../image/krishnaColour.jpg"
import vegetableColour from "../image/vegetableColour.jpg"
import vehiclesColour from "../image/vehiclesColour.jpg"

const NewProducts = () => {
  const colouringBooks1 = {
    heading:"Colour Book Series",
    cards:[
      {image: alphabetColour, name: "Alphabet Colour Book"},
      {image: animalColour, name: "Animal Colour Book"},
      {image: birdsColour, name: "Birds Colour Book"},
      {image: cartoonColour, name: "Cartoon Colour Book"},
      {image: flowerColour, name: "Flower Colour Book"}
    ] 
  };

  const colouringBooks2 = {
    heading:"Colour Book Series",
    cards:[
      {image: fruitsColour, name: "Fruits Colour"},
      {image: krishnaColour, name: "Krishna Colour"},
      {image: vegetableColour, name: "VegetableColour"},
      {image: vehiclesColour, name: "VehiclesColour"}
    ] 
  };

  return (
    <>
    <Navbar/>
    <Card data={colouringBooks1}/>
    <Card data={colouringBooks2}/>
    <Footer/>
    </>
  )
}

export default NewProducts