import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const About = () => {
  const aboutUs = `Welcome to Gyan Bhawan where every child’s imagination comes to life through the magic of books. 
    We believe that every child deserves access to high-quality literature, and our mission is to make that a reality by
     offering a wide range of children’s books at affordable prices.
     Our commitment goes beyond just offering great books. We are dedicated to ensuring that our prices are as accessible
      as possible without compromising on quality. We work tirelessly to source and produce books that meet the highest 
      standards, making it easier for parents and educators to provide children with the best reading experiences without
       breaking the bank. Our vision extends beyond merely selling books. We see ourselves as collaborators in fostering a lifelong love of 
       reading and learning. By choosing Gyan Bhawan, you’re not just purchasing books; you’re investing in a brighter 
       future for children. We invite you to join us in this mission to inspire, educate, and create a lasting impact through
        the joy of reading.`;

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center py-16 bg-[#d8794a]">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl text-white text-center mb-4">Gyan Bhawan</h1>
          <h4 className="text-2xl text-white text-center mb-6">Our Mission</h4>
          <p className="text-lg text-white text-center leading-relaxed">
            {aboutUs}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
