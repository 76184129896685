import React from "react";
import Footer from "./Footer";
import Main from "./Main";
import Navbar from "./Navbar";

const Layout = () => {
  return (
   <>
        <Navbar/>
      <Main/>
      <Footer/>
    </>
  );
};

export default Layout;
