import React from 'react'
import Navbar from './Navbar'
import Card from './Card'
import Footer from './Footer'
import allInOne180 from "../image/allInOne180.png";
import allInOneBig from "../image/allInOneBig.png";
import allInOneRS from "../image/allInOneRS.png";
import allInOneWB200 from "../image/allInOneWB200.png";
import allInOneYellow from "../image/allInOneYellow.png";

const BestSelling = () => {
  const allInOneBookSeries = {
    heading:"All In One Book Series",
    cards:[
      {image: allInOne180, name: "All In One 180"},
      {image: allInOneBig, name: "All In One Big"},
      {image: allInOneRS, name: "All In One Red small"},
      {image: allInOneWB200, name: "All In One Writing Book"},
      {image: allInOneYellow, name: "All In One Yellow"}
    ]  
  };


  return (
    <>
    <Navbar/>
    <Card data={allInOneBookSeries}/>
    <Footer/>
    </>
  )
}

export default BestSelling