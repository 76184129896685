import React from "react";

const Card = ({ data }) => {
  return (
    <div className="w-full max-w-7xl mx-auto p-4 bg-[#ecedee] my-8 rounded-lg shadow-md">
      <h1 className="text-[#1b1b28] font-semibold text-2xl pl-4 mb-4">
        {data.heading}
      </h1>
      <div className="flex flex-col md:flex-row justify-around items-center gap-4">
        {data.cards.map((card, index) => (
          <div key={index} className="w-full md:w-[16rem] bg-white rounded-lg shadow-md overflow-hidden">
            <img src={card.image} alt={card.name} className="w-full h-100 object-cover" />
            <h4 className="text-[#753737] text-center font-semibold py-4">
              {card.name}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
