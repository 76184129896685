import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link if you're using react-router
import logo from "../image/logGyanBhawan.jpg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-lg font-bold">
          <Link to="/" className="block">
            <img src={logo} alt="Gyan Bhawan Logo" className="h-12 w-12" />
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={toggleMenu}
            aria-label="Toggle navigation"
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex space-x-4 mr-[2rem]">
          <Link to="/" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Home</Link>
          <Link to="/colouringBooks" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Colouring Books</Link>
          <Link to="/writingBooks" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Writing Books</Link>
          <Link to="/bestselling" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Best Selling</Link>
          <Link to="/about" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">About Us</Link>
          <Link to="/contact" className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Contact Us</Link>
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="text-white hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              Downloads
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg">
                <a
                  href="/files/Books-Catalog.pdf"
                  download
                  className="block text-white hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Books Catalogue
                </a>
                <a
                  href="/files/Price-List.pdf"
                  download
                  className="block text-white hover:bg-gray-600 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Price List
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="text-center space-y-2"> {/* Add vertical spacing between items */}
          <Link to="/" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Home</Link>
          <Link to="/colouringBooks" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Colouring Books</Link>
          <Link to="/writingBooks" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Writing Books</Link>
          <Link to="/bestselling" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Best Selling</Link>
          <Link to="/about" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">About Us</Link>
          <Link to="/contact" className="block text-white hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Contact Us</Link>
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="block text-white ml-[25vh] hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}
            >
              Downloads
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 mr-[18vh] w-48 bg-gray-700 rounded-md shadow-lg">
                <a
                  href="/files/Books-Catalog.pdf"
                  download
                  className="block text-white hover:bg-gray-600 px-3 py-2 rounded-md text-base font-medium"
                >
                  Books Catalogue
                </a>
                <a
                  href="/files/Price-List.pdf"
                  download
                  className="block text-white hover:bg-gray-600 px-3 py-2 rounded-md text-base font-medium"
                >
                  Price List
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
