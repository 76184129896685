import React from "react";
import banner from "../image/Gyan Bhawan.png";
import cursive1big from "../image/cursive1big.png";
import cursive2big from "../image/cursive2big.png";
import cursive3big from "../image/cursive3big.png";
import cursive4big from "../image/cursive4big.png";
import cursive5big from "../image/cursive5big.png";
import sulekh0big from "../image/sulekh0big.png";
import sulekh1big from "../image/sulekh1big.png";
import sulekh2big from "../image/sulekh2big.png";
import sulekh3big from "../image/sulekh3big.png";
import sulekh4big from "../image/sulekh4big.png";
import allInOne180 from "../image/allInOne180.png";
import allInOneBig from "../image/allInOneBig.png";
import allInOneRS from "../image/allInOneRS.png";
import allInOneWB200 from "../image/allInOneWB200.png";
import allInOneYellow from "../image/allInOneYellow.png";
import geographicPracticalCopy from "../image/geographicPracticalCopy.png";
import biologyPracticalCopy from "../image/biologyPracticalCopy.png";
import botanyPracticalCopy from "../image/botanyPracticalCopy.png";
import physicsPracticalCopy from "../image/physicsPracticalCopy.png";
import sciencePracticalCopy from "../image/sciencePracticalCopy.png";
import alphabetColour from "../image/alphabetColour.jpg";
import animalColour from "../image/animalColour.jpg";
import birdsColour from "../image/birdsColour.jpg";
import cartoonColour from "../image/cartoonColour.jpg";
import flowerColour from "../image/flowerColour.jpg";



import Card from "./Card";

const Main = () => {
  const cursiveBookSeries ={
    heading:"Cursive Book Serires",
    cards:[
      {image: cursive1big, name: "Vidya Cursive 1 Jumbo"},
      {image: cursive2big, name: "Vidya Cursive 2 Jumbo"},
      {image: cursive3big, name: "Vidya Cursive 3 Jumbo"},
      {image: cursive4big, name: "Vidya Cursive 4 Jumbo"},
      {image: cursive5big, name: "Vidya Cursive 5 Jumbo"}
    ]
  };

  const sulekhBookSeries = {
    heading:"Sulekh Book Series",
    cards:[
      {image: sulekh0big, name: "Vidya Sulekh 0 Jumbo"},
      {image: sulekh1big, name: "Vidya Sulekh 2 Jumbo"},
      {image: sulekh2big, name: "Vidya Sulekh 3 Jumbo"},
      {image: sulekh3big, name: "Vidya Sulekh 4 Jumbo"},
      {image: sulekh4big, name: "Vidya Sulekh 5 Jumbo"}
    ]
  };

  const allInOneBookSeries = {
    heading:"All In One Book Series",
    cards:[
      {image: allInOne180, name: "All In One 180"},
      {image: allInOneBig, name: "All In One Big"},
      {image: allInOneRS, name: "All In One Red small"},
      {image: allInOneWB200, name: "All In One Writing Book"},
      {image: allInOneYellow, name: "All In One Yellow"}
    ]  
  };

  const practicalCopySeries = {
    heading:"Practical Copy",
    cards:[
      {image: geographicPracticalCopy, name: "Geography Practical Copy"},
      {image: biologyPracticalCopy, name: "Biology Practical Copy"},
      {image: botanyPracticalCopy, name: "Botany Practical Copy"},
      {image: physicsPracticalCopy, name: "Physics Practical Copy"},
      {image: sciencePracticalCopy, name: "Science Practical Copy"}
    ]  
  };

  const colouringBooks = {
    heading:"Colour Book Series",
    cards:[
      {image: alphabetColour, name: "Alphabet Colour Book"},
      {image: animalColour, name: "Animal Colour Book"},
      {image: birdsColour, name: "Birds Colour Book"},
      {image: cartoonColour, name: "Cartoon Colour Book"},
      {image: flowerColour, name: "Flower Colour Book"}
    ] 
  };
  return (
    <>
     <div className="w-full overflow-hidden">
      <img 
        className="w-full h-auto object-cover" 
        src={banner} 
        alt="Banner"
      />
    </div>
      <Card data={cursiveBookSeries}/>
       <Card data={sulekhBookSeries}/>
       <Card data={allInOneBookSeries}/>
       <Card data={practicalCopySeries}/>
       <Card data={colouringBooks}/>
    </>
  );
};

export default Main;
